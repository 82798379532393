<template>
  <div class="container-fluid" id="customer-container">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
            my-schedule
            mb-2
          "
        >
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="font-weight-bold">Customers</h4>
          </div>
          <div class="create-workform">
            <div
              class="
                d-flex
                flex-wrap
                align-items-center
                justify-content-between
              "
            >
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <search
                    :search="search"
                    :placeholder="'Search'"
                    @searchFilter="handleSearchCustomer"
                    v-model="search"
                  >
                  </search>
                </div>
                <router-link
                  :to="{ name: 'customer.customeradd' }"
                  class="btn btn-success btn-sm d-flex align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-1"
                    width="15"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                  <div class="white-space">Add Customer</div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-block card-stretch">
              <div class="card-body-2">
                <div
                  class="
                    mb-2
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-between
                  "
                >
                  <h5>Customers List</h5>
                  <div class="d-flex flex-wrap mt-2 mobile-align-customer">
                    <div class="sorting-div">
                      <sortingText
                        :sortingData="sortingData"
                        :sorting="sorting"
                        @changeSorting="onChangeSorting"
                      ></sortingText>
                    </div>

                    <div class="d-flex">
                      <div class="ml-2 filter-div">
                        <button
                          class="
                            btn btn-primary btn-sm
                            d-flex
                            align-items-center
                          "
                          @click="filterClick()"
                          v-on:click="showFilters = !showFilters"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="mr-1"
                            width="15"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                           <div class="notify">Filters
                            <div class="badge"  v-if="countFilter">
                              <div class="message-count">{{countFilter}}</div>
                            </div>
                          </div>
                        </button>
                      </div>

                      <div class="ml-2">
                         <button
                          class="
                            btn btn-warning btn-sm
                            d-flex
                            align-items-center
                            reset-btn-padding-onlist
                          "
                          @click="resetFilters"
                          
                          title="Reset Filters"
                        >
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="15" height="15" viewBox="0 0 1280.000000 1266.000000"
                            preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,1266.000000) scale(0.100000,-0.100000)"
                            fill="white" stroke="none">
                            <path d="M6130 12653 c-1136 -47 -2253 -397 -3200 -1002 -1163 -744 -2048
                            -1830 -2525 -3096 -433 -1150 -520 -2406 -249 -3595 169 -740 463 -1430 880
                            -2063 765 -1161 1874 -2034 3189 -2509 977 -354 2044 -467 3080 -328 1388 186
                            2694 829 3675 1810 129 129 1201 1318 1207 1339 2 8 -1951 1746 -1964 1746 -5
                            0 -200 -214 -433 -475 -596 -668 -655 -732 -782 -847 -599 -539 -1284 -857
                            -2083 -964 -292 -39 -726 -36 -1025 7 -1069 153 -1996 719 -2605 1589 -746
                            1066 -863 2457 -305 3632 359 755 979 1375 1745 1744 387 186 728 287 1180
                            351 213 29 712 33 927 5 649 -82 1206 -288 1723 -638 269 -182 481 -372 781
                            -704 153 -168 162 -181 146 -196 -9 -9 -287 -254 -617 -544 -330 -291 -601
                            -532 -603 -537 -2 -4 4 -8 13 -8 8 0 305 -68 658 -150 353 -83 851 -200 1107
                            -260 256 -60 726 -170 1045 -245 319 -75 831 -195 1138 -267 307 -71 560 -128
                            562 -126 3 2 -47 960 -110 2129 -63 1168 -119 2221 -126 2339 -6 118 -14 245
                            -17 282 l-7 67 -520 -459 c-286 -252 -522 -459 -525 -459 -3 -1 -66 67 -140
                            150 -194 219 -425 458 -575 595 -1250 1147 -2934 1759 -4645 1687z"/>
                            </g>
                            </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <transition name="slide-fade">
                  <div class="row d-flex pb-5 pr-5 pl-5" v-if="showFilters">
                    <div class="col-md-3 filter-tabs">
                      <label
                        for="car-make"
                        class="
                          form-label
                          text-uppercase text-muted
                          filter-label-heading
                          mt-3
                        "
                        >From Date (Date Joined)</label
                      >
                      <b-input-group :class="{'borderColorFilter': gte !== null ? true : false}">
                        <b-form-datepicker
                          id="datepicker-from-date"
                          v-model="gte"
                          size="sm"
                          calendar-width="100%"
                          placeholder="Select"
                          @context="onContext"
                          @input="onChangeFromDate($event)"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                        >
                        </b-form-datepicker>
                        <template v-slot:append>
                          <button
                            class="filter-cross-btn"
                            @click="removeFromDate()"
                          >
                            x
                          </button>
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-md-3 filter-tabs">
                      <label
                        for="car-make"
                        class="
                          form-label
                          text-muted text-uppercase
                          filter-label-heading
                          mt-3
                        "
                        >To Date (Date Joined)</label
                      >
                      <b-input-group :class="{'borderColorFilter': lte !== null ? true : false}">
                        <b-form-datepicker
                          id="datepicker-to-date"
                          v-model="lte"
                          size="sm"
                          calendar-width="100%"
                          placeholder="Select"
                          @context="onContext"
                          @input="onChangeToDate($event)"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                        ></b-form-datepicker>
                        <template v-slot:append>
                          <button
                            class="filter-cross-btn"
                            @click="removeToDate()"
                          >
                            x
                          </button>
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-md-2">
                      <label
                        for="car-make"
                        class="
                          form-label
                          text-muted text-uppercase
                          filter-label-heading
                          mt-3
                        "
                        >Country</label
                      >

                      <multiselect

                        v-model="country"
                        :options="countryArray"
                        placeholder="Select"
                        :max="countryArray.length"
                        @select="onChangeCountry"
                        @remove="removeCountry"
                        :class="{'borderColorFilter': country !== null ? true : false, singleOptions: true}"
                        :show-labels="false"
                        :allow-empty="true"
                      >
                      </multiselect>
                    </div>
                    <div class="col-md-2 filter-tabs">
                      <label
                        for="car-make"
                        class="
                          form-label
                          text-muted
                          filter-label-heading
                          text-uppercase
                          mt-3
                        "
                        >Referrer</label
                      >

                      <multiselect
                        v-model="referrerType"
                        :options="referrerTypeArray"
                        placeholder="Select"
                        :max="referrerTypeArray.length"
                        @select="onChangeReferrer"
                        @remove="removeReferrer"
                        :class="{'borderColorFilter': referrerType !== null ? true : false, singleOptions: true}"
                        :show-labels="false"
                        :allow-empty="true"
                      >
                      </multiselect>
                    </div>
                    <div class="col-md-2 d-flex">
                      <div class="row">
                        <div class="col">
                          <label
                            for="car-make"
                            class="
                              form-label
                              text-muted
                              filter-label-heading
                              text-uppercase
                              mt-3
                              white-space
                            "
                            >Is Staff
                          </label>
                          <b-form-checkbox
                            size="lg"
                            v-model="checkedIsStaff"
                            @input="handleIsStaff"
                            name="check-button"
                            switch
                            :class="{'borderColorFilter': isStaff, 'is-true':true}"
                          >
                          </b-form-checkbox>
                        </div>

                        <div class="col">
                          <label
                            for="car-make"
                            class="
                              form-label
                              text-muted
                              filter-label-heading
                              text-uppercase
                              mt-3
                              white-space
                            "
                            >Is Active
                          </label>
                          <b-form-checkbox
                            size="lg"
                            v-model="checkedIsActive"
                            @input="handleIsActive"
                            name="check-button"
                            switch
                            :class="{'borderColorFilter': isActive, 'is-true':true}"
                          >
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>

                <div class="table-responsive table-zoom">
                  <table class="table data-table mb-0 table-customer">
                    <thead class="table-color-heading">
                      <tr class="">
                        <th scope="col">View</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Referral</th>
                        <th scope="col">Date Joined</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="white-space-no-wrap transition-row"
                        v-for="customer in users.edges"
                        :key="customer.id"
                      >
                        <td>
                          <div
                            class="
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <router-link
                              :to="'customerview/' + customer.node.phone"
                              class="click-area-extend"
                             
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="text-secondary"
                                width="20"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                />
                              </svg>
                            </router-link>
                          </div>
                        </td>
                        <td class="table-font">
                          <div class="data-content">
                            {{
                              customer.node.firstName +
                              " " +
                              customer.node.lastName
                            }}
                            <p class="m-0 text-secondary small">
                              {{ customer.desc }}
                            </p>
                          </div>
                        </td>
                        <td class="table-font">{{ customer.node.email }}</td>
                        <td class="table-font">
                          <div title="Click To Copy">
                            <div
                              @click.prevent="
                                copyPhoneToClipboard(customer.node.phone)
                              "
                              class="customer-phone"
                            >
                              {{ customer.node.phone }}
                            </div>
                          </div>
                        </td>

                        <td class="table-font" v-if="!!customer.node.referrer">
                          {{ customer.node.referrer.code }}
                        </td>
                        <td v-else>-</td>
                        <td class="table-font">
                            {{ customer.node.dateJoined | dateDayFormat }}{{", "}}{{ customer.node.dateJoined | timeFormat }}
                        </td>
                      </tr>
                      <tr v-if="totalRows === 0">
                        <td colspan="6" align="center">No data found</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="p-3 row">
                  <div class="col-sm-12 col-md-6 d-flex align-items-center">
                    <div>
                      Total Users: <b>{{ totalRows }}</b>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 d-flex justify-content-end">
                    <pagination
                      :currentPage="currentPage"
                      :perPage="perPage"
                      :totalRows="totalRows"
                      @changePage="handlePageChange"
                    ></pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import search from "../../../components/filters/search.vue";
import pagination from "../../../components/pagination/pagination.vue";
import sortingText from "../../../components/filters/sorting.vue";
import { GET_CUSTOMERS } from "../../../graphql/query";
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { pagination, search, sortingText },
  name: "customer",
  data() {
    return {
      checkedIsStaff: false,
      checkedIsActive: true,
      uppercased: [],
      // sortingData: [
      //   "DATE_JOINED - ASC",
      //   "DATE_JOINED - DESC",
      //   "LAST_LOGIN - ASC",
      //   "LAST_LOGIN - DESC",
      //   "ID - ASC",
      //   "ID - DESC",
      // ],

      sortingData: [
        { id: "DATE_JOINED - ASC", name: "Date Joined - Asc." },
        { id: "DATE_JOINED - DESC", name: "Date Joined - Desc." },
        { id: "LAST_LOGIN - ASC", name: "Last Login - Asc." },
        { id: "LAST_LOGIN - DESC", name: "Last Login - Desc." },
        { id: "ID - ASC", name: "Id - Asc." },
        { id: "ID - DESC", name: "Id - Desc." },
        // "DATE_JOINED - ASC",
        // "DATE_JOINED - DESC",
        // "LAST_LOGIN - ASC",
        // "LAST_LOGIN - DESC",
        // "ID - ASC",
        // "ID - DESC",
      ],
      countryArray: ["UAE", "INDIA"],
      referrerTypeArray: [
        "MARKETING_CAMPAIGNS",
        "OTHER_CUSTOMERS",
        "AFFILIATES",
      ],
      // sorting: null,
      //  sorting: "DATE JOINED - DESC",
      sorting: { id: "DATE_JOINED - DESC", name: "Date Joined - Desc." },
      showFilters: false,
      users: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      after: null,
      before: null,
      hasNextPage: true,
      hasPreviousPage: false,
      last: null,
      first: null,
      search: null,
      searchText: null,
      direction: "",
      field: "",
      country: null,
      gte: null,
      isActive: true,
      isStaff: null,
      lte: null,
      referrerType: null,
      resetSvg: require("../../../assets/images/svg/reset.svg"),
      countFilter:0,
      customerFilters:{
        country: null,
        referrerType: null,
        filter_date_lte:null,
        filter_date_gte:null,
        isStaff:null,
        isActive:true,
      },
      customerSearch:null
    };
  },
   computed : {
      ...mapGetters({
          stateCustomerFilters: 'customerFilters',
          stateSearch: 'customerSearch'
      })
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.prevRoute = from
        if(from.name !== 'customer.customerview' && from.name !== 'customer.customeradd' && from.name !== null ){
          vm.customerSearch = null;
          vm.changeSearch(vm.customerSearch);
        }
      })
    },
  mounted() {
     
    if(localStorage.getItem("vuex") !== null){
      this.first = this.perPage;
      (this.direction = "DESC"), (this.field = "DATE_JOINED");
      
      // this.getStateLocalStorage();
     
      this.customerFilters = this.stateCustomerFilters;
      this.changeCustomerFilters(this.stateCustomerFilters);

      this.customerSearch = this.stateSearch;
      this.changeSearch(this.stateSearch);
      this.getCustomer();

    }
  },
   watch:{
     customerFilters:function(){
        this.changeCustomerFilters(this.customerFilters)
     },
     customerSearch:function(){
        this.changeSearch(this.customerSearch)
     }
    },
  methods: {
    ...mapActions({
      customerFilterChange: 'customerFilterAction',
      searchChange: 'searchAction'
      }),
       filterClick(){
      this.countFilter = document.querySelectorAll('.borderColorFilter').length;
    },
    changeCustomerFilters(value){
      this.countFilter = 0;
      this.customerFilterChange({ customerFilters: value })
      if(value.country !== null || value.referrerType !== null ||
      value.filter_date_lte !== null || value.filter_date_gte !== null ||
      value.isStaff !== null || value.isActive !== null || 
      value.customerSearch !== null ){
        if(value.country){
          this.showFilters = true;
          this.country = this.customerFilters.country;
          this.countFilter += 1;
        }
        else{
          this.country = null;
          this.showFilters = false;
        }

        if(value.referrerType){
          this.showFilters = true;
          this.referrerType = this.customerFilters.referrerType;
          this.countFilter += 1;
        }
        else{
          this.referrerType = null;
          this.showFilters = false;
        }

        if(this.customerFilters.filter_date_lte){
          this.lte = this.customerFilters.filter_date_lte;
          this.countFilter += 1;
        }

        if(this.customerFilters.filter_date_gte){
          this.gte = this.customerFilters.filter_date_gte;
          this.countFilter += 1;
        }

        if(this.customerFilters.isStaff){
          this.isStaff = this.customerFilters.isStaff;
          this.checkedIsStaff = this.customerFilters.isStaff;
          this.countFilter += 1;
        }

        if(this.customerFilters.isActive === true){
          this.isActive = this.customerFilters.isActive;
          this.checkedIsActive = this.customerFilters.isActive;
          this.countFilter += 1;
        }

      }else{
        this.showFilters = false;
      }

    },

    changeSearch(value){
      // this.countFilter = 0;
      this.searchChange({ customerSearch: value });
       if(this.customerSearch){
          this.search = this.customerSearch;
        }
    },
    // getStateLocalStorage(){
    //   var data = JSON.parse(localStorage.getItem("vuex"));
    //   this.customerFilters = data.customerFilters;
    //     if(this.customerFilters.country !== null){
    //       if(this.customerFilters.country){
    //         // this.statusData = data.customerFilters.country;
    //         this.country = this.customerFilters.country;
    //         // this.countFilter += 1;
    //       }
    //       else{
    //         // this.statusData = '';
    //         this.country = null;
    //       }
    //     }
    // },
    getCustomer() {
      this.$apollo
        .query({
          query: GET_CUSTOMERS,
          variables: {
            first: this.first,
            after: this.after,
            before: this.before,
            search: this.search,
            direction: this.direction,
            field: this.field,
            country: this.country,
            gte: this.gte,
            isActive: this.isActive,
            isStaff: this.isStaff,
            lte: this.lte,
            last: this.last,
            referrerType: this.referrerType,
          },
        })
        .then((data) => {
          this.users = data.data.bwsDashboardUsers;
          this.totalRows = data.data.bwsDashboardUsers.totalCount;
          this.after = data.data.bwsDashboardUsers.pageInfo.endCursor;
          this.before = data.data.bwsDashboardUsers.pageInfo.startCursor;
          this.hasNextPage = data.data.bwsDashboardUsers.pageInfo.hasNextPage;
          this.hasPreviousPage = data.data.bwsDashboardUsers.pageInfo.hasPreviousPage;
        });
    },
    handlePageChange(event) {
      if (event > this.currentPage && this.hasNextPage) {
        this.currentPage = event;
        this.before = null;
        this.last = null;
        this.first = this.perPage;
        this.after;
        this.getCustomer();
      } else if (event < this.currentPage && this.hasPreviousPage) {
        this.currentPage = event;
        this.after = null;
        this.before;
        this.last = this.perPage;
        this.first = null;
        this.getCustomer();
      }
    },
    handleSearchCustomer(event) {
      this.search = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.customerSearch = event
      this.changeSearch(this.customerSearch);
      this.getCustomer();
    },

    onChangeSorting(event) {
      if (event.id === null) {
        this.field = "DATE_JOINED";
        this.direction = "DESC";
        this.before = null;
        this.after = null;
        this.currentPage = 1;
        this.last = null;
        this.first = this.perPage;
        this.getCustomer();
      } else {
        var res = event.id.split("-");
        this.field = res[0].replaceAll(" ", "");
        this.direction = res[1].replaceAll(" ", "");
        this.before = null;
        this.after = null;
        this.last = null;
        this.first = this.perPage;
        this.currentPage = 1;
        this.getCustomer();
      }
    },
    onContext(ctx) {
      this.nextServiceDue = ctx.selectedYMD;
    },
    getCustomCountry(option) {
      return option;
    },
    getCustomReferrer(opt) {
      return opt;
    },
    onChangeFromDate(event) {
      this.gte = event;
      this.customerFilters.filter_date_gte = event;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.last = null;
      this.first = this.perPage;
      this.changeCustomerFilters(this.customerFilters);
      this.getCustomer();
    },

    onChangeToDate(event) {
      this.lte = event;
      this.customerFilters.filter_date_lte = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.changeCustomerFilters(this.customerFilters);
      this.getCustomer();
    },
    removeFromDate() {
      this.gte = null;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.last = null;
      this.first = this.perPage;
      this.customerFilters.filter_date_gte = null;
      this.changeCustomerFilters(this.customerFilters);
      this.getCustomer();
    },
    removeToDate() {
      this.lte = null;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.last = null;
      this.first = this.perPage;
      this.customerFilters.filter_date_lte = null;
      this.changeCustomerFilters(this.customerFilters);
      this.getCustomer();
    },
    onChangeCountry(event) {
      this.country = event;
      this.customerFilters.country = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.changeCustomerFilters(this.customerFilters);
      this.getCustomer();
    },
    removeCountry(selectedOption) {
      if (selectedOption) {
        this.country = null;
        this.before = null;
        this.after = null;
        this.currentPage = 1;
        this.last = null;
        this.first = this.perPage;
        this.customerFilters.country = null;
        this.changeCustomerFilters(this.customerFilters);
        this.getCustomer();
      }
    },

    onChangeReferrer(event) {
      this.referrerType = event;
      this.customerFilters.referrerType = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.changeCustomerFilters(this.customerFilters);
      this.getCustomer();
    },
    removeReferrer(selectedOption) {
      if (selectedOption) {
        this.referrerType = null;
        this.before = null;
        this.after = null;
        this.last = null;
        this.first = this.perPage;
        this.currentPage = 1;
        this.customerFilters.referrerType = null;
        this.changeCustomerFilters(this.customerFilters);
        this.getCustomer();
      }
    },
    handleIsActive() {
      this.isActive = this.checkedIsActive;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.customerFilters.isActive = this.checkedIsActive;
      this.changeCustomerFilters(this.customerFilters);
      this.getCustomer();
    },
    handleIsStaff() {
      if(this.checkedIsStaff ===  false){
         this.isStaff = null;
      }
      else{
        this.isStaff = this.checkedIsStaff;
      }
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.customerFilters.isStaff = this.isStaff;
      this.changeCustomerFilters(this.customerFilters);
      this.getCustomer();
    },
    resetFilters() {
      this.currentPage = 1;
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      (this.last = null),
      (this.sorting = null),
      (this.status = null),
      (this.direction = "DESC"),
      (this.field = "DATE_JOINED"),
      (this.gte = null);
      this.lte = null;
      this.country = null;

      this.checkedIsStaff = null;
      this.checkedIsActive = true;
      this.handleIsStaff();
      this.handleIsActive();

      this.referrerType = null;
       
      this.countFilter = 0;
      
      this.customerFilters.isStaff = null;
      this.customerFilters.isActive = this.checkedIsActive;
      this.customerFilters.country = null;
      this.customerFilters.filter_date_gte = null;
      this.customerFilters.filter_date_lte = null;
      this.customerFilters.referrerType = null;
      // localStorage.removeItem("vuex");
      this.changeCustomerFilters(this.customerFilters);
      this.getCustomer();
      location.reload();
    },
    copyPhoneToClipboard(phone) {
      var input_temp = document.createElement("textarea");
      input_temp.setAttribute("class", "text-area-clipboard");
      input_temp.innerHTML = phone;
      document.body.appendChild(input_temp);
      input_temp.select();
      input_temp.setSelectionRange(0, 99999);
      document.execCommand("copy");

      var message = "Copied";
      this.$bvToast.toast(phone + " " + `${message}`, {
        toaster: "b-toaster-top-center",
        variant: "success",
        solid: true,
        toastClass: "text-center toast-box toast-style",
      });
    },
  },
};
</script>

<style>
.filter-btn {
  font-size: 1.1rem !important;
}
table.table-customer {
  text-align: center;
}
.singleOptions .multiselect__tags {
  min-height: 47px;
}
.transition-row {
  transition: transform 0.1s;
}
.transition-row:hover {
  background-color: rgb(245, 245, 245);
  /* transform: scale(0.99); */
}
.click-area-extend {
  padding: 0.25rem 1rem 0.25rem 1rem;
  cursor: pointer;
}
.toast-style {
  font-size: 1.2rem;
  padding: 1rem;
}
.customer-phone:hover {
  color: blue;
  cursor: pointer;
}
.text-area-clipboard {
  border: none;
  color: white;
  height: 0;
  width: 0;
}
.table-zoom {
  zoom: 90%;
}
.white-space {
  overflow: auto;
  white-space: nowrap;
}

.card-body-2 {
  padding: 10px 20px 20px 20px;
}
.sorting-div .singleOptions .multiselect__tags {
  min-height: 10px !important;
}

.singleOptions .multiselect__tags {
  min-height: 37px !important;
}

.multiselect {

    min-height: 37px;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 13px !important;
}

.multiselect__input,
.multiselect__single {
  min-height: 12px !important;
  line-height: 14px !important;
}

.multiselect__select {
  height: 31px !important;
}

.filter-cross-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0 0.75rem 0 0.75em;
  border-radius: 0.25rem;
}

.b-form-btn-label-control.form-control > .form-control.form-control-sm {
  overflow: auto !important;
  white-space: nowrap !important;
}
.reset-btn-padding {
  padding: 0.2rem;
}

.reset-btn-padding-onlist {
  padding: 0.4rem;
}

.notify .badge {
  background: radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
  background: -moz-radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
  background: -ms-radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
  background: -o-radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
  background: -webkit-radial-gradient( 5px -9px, circle, white 8%, #ed2324 26px );
  background-color: #ed2324;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 5% 5%;
  float: right;
  display: block;
  position: relative;
  top: -16px;
  left: 12px;
}

.message-count {
  position: relative;
  display: block;
  font: bold 14px/13px Helvetica, Verdana, Tahoma;
  text-align: center;
  margin: 0;
  top: 2px;
}

#customer-container .borderColorFilter{
  /* background-color: gray; */
  border: 2px solid #9a18ffd1 !important;
  border-radius: 5px;
}

#customer-container .filter-tabs.col-md-3 , #customer-container .filter-tabs.col-md-2{
    flex: 0 0 20%;
    max-width: 20%;
}

#customer-container .borderColorFilter.is-true{
  border: none !important;
}

#customer-container .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #3378FF;
    border-color: #3378FF;
    border: 2px solid #9a18ffd1 !important;
    border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .table th,
  .table td {
    padding: 10px 5px;
  }

  #customer-container .filter-tabs.col-md-3 , #customer-container .filter-tabs.col-md-2{
    flex: 0 0 100%;
    max-width: 100%;
  } 
}

@media only screen and (max-width: 470px) {
 
}

@media only screen and (max-width: 417px) {
  .sorting-div{
    margin-bottom: 1rem;
  }
   /* .mobile-align-customer {
    justify-content: flex-start;
  } */
  .filter-div{
    margin-left: 0rem !important;
  }
  .filter-label-heading {
    font-weight: 600;
    font-size: 0.9rem;
  }
}
</style>

